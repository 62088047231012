<template>
  <div class="main">
    <header-t/>
    <nav2/>
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/login' }">个人/企业登录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrapper entry-container regist-container mb20 shadow">
      <div class="left-container">
        <div class="layui-tab layui-tab-brief">
          <!-- <ul class="layui-tab-title">
            <li class="layui-this">
              <a
                href=""
                style="font-size: 16px; color: #000; font-weight: bold"
              >
                个人/企业登陆
              </a>
            </li>
          </ul> -->
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="个人登录" name="1">
              <el-form
                  ref="loginDataForm"
                  :model="loginForm"
                  :rules="loginRules"
                  class="form_width"
                  label-width="180px"
                  size="small"
              >
                <el-form-item v-if="activeName==='1'" prop="username" label="登录账号：">
                  <el-input
                      ref="username"
                      v-model="loginForm.username"
                      clearable
                      placeholder="请输入手机号码或身份证"
                      name="username"
                      class="username"
                      tabindex="1"
                      autocomplete="on"
                      @change="changeUserName"
                  />
                </el-form-item>
                <!-- <el-tooltip
                    v-model="capsTooltip"
                    content="大写锁定已打开"
                    placement="right"
                    manual
                  > -->
                <el-form-item v-if="activeName==='1'" prop="password" label="登录密码：">
                  <el-input
                      :key="passwordType"
                      ref="password"
                      v-model="loginForm.password"
                      clearable
                      :type="passwordType"
                      placeholder="请输入密码"
                      name="password"
                      class="password"
                      tabindex="2"
                      autocomplete="on"
                      @keyup.native="checkCapslock"
                      @blur="capsTooltip = false"
                      @keyup.enter.native="handleLogin"
                  />
                  <!-- <span class="show-pwd" @click="showPwd">
                        <svg-icon
                          :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
                        />
                      </span> -->
                </el-form-item>
                <!-- </el-tooltip> -->
                <el-form-item v-if="activeName==='1'" prop="captcha" label="验证码：">
                  <el-input
                      ref="captcha"
                      v-model="loginForm.captcha"
                      clearable
                      placeholder="请输入验证码"
                      maxlength="4"
                      name="captcha"
                      class="captcha"
                      tabindex="3"
                      @keyup.enter.native="handleLogin"
                  />
                  <img
                      class="captchaImage"
                      :src="captchaImage"
                      @click="captcheClick"
                  />
                  <!-- <el-button v-bind:disabled="dis" class="sendCode" type="primary" @click="handlegetBtn">
                      <span v-if="show">发送验证码</span>
                      <span v-else>重新发送({{ count }}s)</span>
                    </el-button> -->
                </el-form-item>
                <!-- <el-form-item>
                  <el-checkbox v-model="checked" style="float:left;" @change="ChangeRember">记住我</el-checkbox>
                </el-form-item> -->
                <div v-if="activeName==='1'" class="btn-wrap">
                  <el-button
                      :loading="loading"
                      type="primary"
                      class="submit-btn"
                      @click.native.prevent="handleLogin"

                      :disabled="LoginBtn"
                  >登录
                  </el-button
                  >
                </div>
                <p class="find-password" @click="findPassword">忘记密码</p>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="企业登录" name="2">
              <el-form
                  ref="loginDataForm"
                  :model="loginForm"
                  :rules="loginRules"
                  class="form_width"
                  label-width="180px"
                  size="small"
              >
                <el-form-item v-if="activeName==='2'" prop="userEname" label="登录账号：">
                  <el-input
                      ref="userEname"
                      v-model="loginForm.userEname"
                      clearable
                      placeholder="请输入手机号码或统一信用代码"
                      name="userEname"
                      class="username"
                      tabindex="1"
                      autocomplete="on"
                      @change="changeUserName"
                  />
                </el-form-item>
                <!-- <el-tooltip
                    v-model="capsTooltip"
                    content="大写锁定已打开"
                    placement="right"
                    manual
                  > -->
                <el-form-item v-if="activeName==='2'" prop="password" label="登录密码：">
                  <el-input
                      :key="passwordType"
                      ref="password"
                      v-model="loginForm.password"
                      clearable
                      :type="passwordType"
                      placeholder="请输入密码"
                      name="password"
                      class="password"
                      tabindex="2"
                      autocomplete="on"
                      @keyup.native="checkCapslock"
                      @blur="capsTooltip = false"
                      @keyup.enter.native="handleLogin"
                  />
                  <!-- <span class="show-pwd" @click="showPwd">
                        <svg-icon
                          :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
                        />
                      </span> -->
                </el-form-item>
                <!-- </el-tooltip> -->
                <el-form-item v-if="activeName==='2'" prop="captcha" label="验证码：">
                  <el-input
                      ref="captcha"
                      v-model="loginForm.captcha"
                      clearable
                      placeholder="请输入验证码"
                      maxlength="4"
                      name="captcha"
                      class="captcha"
                      tabindex="3"
                      @keyup.enter.native="handleLogin"
                  />
                  <img
                      class="captchaImage"
                      :src="captchaImage"
                      @click="captcheClick"
                  />
                  <!-- <el-button v-bind:disabled="dis" class="sendCode" type="primary" @click="handlegetBtn">
                      <span v-if="show">发送验证码</span>
                      <span v-else>重新发送({{ count }}s)</span>
                    </el-button> -->
                </el-form-item>
                <!-- <el-form-item>
                  <el-checkbox v-model="checked" style="float:left;" @change="ChangeRember">记住我</el-checkbox>
                </el-form-item> -->
                <div v-if="activeName==='2'" class="btn-wrap">
                  <el-button
                      :loading="loading"
                      type="primary"
                      class="submit-btn"
                      @click.native.prevent="handleLogin"

                      :disabled="LoginBtn"
                  >登录
                  </el-button
                  >
                </div>
                <p class="find-password" @click="findPassword">忘记密码</p>
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </div>

      </div>
      <!-- <div class="right-container" style="margin: 25px 0 0 0; width: 524px">
        <div class="qr-item mt50" style="margin-top: 0px !important">
          <div class="qr" style="width: 449px">
            <img
              src="https://www.wlmqcol.com/files/system/2020/05-07/1451280ae6b4785643.png"
              alt=""
            />
          </div>
          <div class="title"></div>
        </div>
      </div> -->
    </div>
    <Footer/>
    <el-dialog
        title="请补全账号资料后进入网站学习"
        :visible.sync="filledUserInfoDialog"
        width="40%"
        :show-close="false"
        :close-on-click-modal="false"
        :modal="true"
    >
      <div class="user-info-content">
        <el-row>
          <el-col :span="8">
            <div class="l-title">昵称：</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="info.userName"></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <div class="l-title">姓名：</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="info.name"></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <div class="l-title">身份证：</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="info.idCard"></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <div class="l-title">性别：</div>
          </el-col>
          <el-col :span="16">
            <div style="margin-top: 8px;">
              <el-radio-group v-model="info.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>

        <el-row v-if="activeName === '1'">
          <el-col :span="8">
            <div class="l-title">是否为少数民族：</div>
          </el-col>
          <el-col :span="16">
            <div style="margin-top: 8px;">
              <el-radio-group v-model="info.minorityNationality">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <div class="l-title">联系电话：</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="info.contactNumber"></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <div class="l-title">邮箱：</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="info.email"></el-input>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <div class="l-title">联系地址：</div>
          </el-col>
          <el-col :span="16">
            <el-input v-model="info.address"></el-input>
          </el-col>
        </el-row>

      </div>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="btnSave">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
import {isId15, isId18, isPhone, validEmail/*,isId15,isId18*/} from '@/utils/validate.js'
import {gen, check, getUserDetail, postUserDetail} from '@/api/common'
import md5 from 'js-md5'
import {getQueryObject} from '@/utils/validate'
// import * as base64 from 'js-base64/base64'
export default {
  name: 'Login',
  components: {
    HeaderT,
    Nav2,
    Footer
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码或身份证！'))
      }/* else if (isPhone(value)) {
        callback(new Error('请输入正确的手机号'))
      }else if(isId15(value) || isId18(value)) {
        callback(new Error('请输入正确的身份证号'))
      }*/ else {
        callback()
      }
    }
    const validateUserEname = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号码或统一信用代码！'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不小于6位数！'))
      } else {
        callback()
      }
    }
    const validateCaptcha = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码！'))
      } else {
        callback()
      }
    }
    return {
      activeName: '1',
      loading: false, // 按钮loading
      passwordType: 'password', // 密码框类型
      // checked:false,//记住我
      loginForm: {
        username: '', // 用户名
        userEname: '', // 企业用户名
        password: '', // 密码
        captcha: '' // 验证码
      },
      captchaToken: '', // 验证码token
      captchaImage: '', // base64 图片
      dis: true,
      show: true,
      isGrey: false, //按钮样式
      timer: null, //计时器倒计时
      filledUserInfoDialog: false, // 个人资料未完善弹窗
      LoginBtn: false,
      count: '',
      radio: '1', //单选框
      info: {}, // 个人资料
      // 表单检验规则
      loginRules: {
        username: [
          {required: true, trigger: 'blur', validator: validateUsername}
        ],
        userEname: [
          {required: true, trigger: 'blur', validator: validateUserEname}
        ],
        password: [
          {required: true, trigger: 'blur', validator: validatePassword}
        ],
        captcha: [
          {required: true, trigger: 'blur', validator: validateCaptcha}
        ]
      },
      sourceUrl: '', //来源地址url
      capsTooltip: false // 大写键盘打开提示
    }
  },
  mounted() {
    this.getImageCode()
    // 来源地址url
    this.sourceUrl = getQueryObject(this.$route.fullPath).redirect
    console.log(this.sourceUrl)
    window.addEventListener('beforeunload', this.logout);
    // console.log(base64.encode(md5('a123456')));
  },
  methods: {
    //生成图形验证码
    getImageCode() {
      const obj = {
        codeType: '1'
      }
      gen(obj).then((res) => {
        console.log(res)
        if (res.status === 200) {
          this.captchaToken = res.data.data.taskId
          this.captchaImage = `data:image/png;base64,${res.data.data.bgPic}`
        }
        // console.log(this.captchaToken);
        // console.log(this.captchaImage);
      })
    },
    //图片验证码校验
    getCheck() {
      if (this.loginForm.captcha) {
        const obj = {
          taskId: this.captchaToken,
          code: this.loginForm.captcha //验证码类型 1图片验证码 2 滑动验证码（暂未实现）
        }
        return new Promise((resolve, reject) => {
          check(obj).then((res) => {
            console.log(res)
            if (res.status === 200) {
              if (res.data.code === 0) {
                resolve()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message,
                  onClose: () => {
                    this.loginForm.captcha = '' //  清空
                    this.captcheClick() // 更新验证码
                  }
                })
                // this.getImageCode() // 更新验证码
                reject()
                throw new Error(res.data.message)
              }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })

              reject()
              throw new Error(res.data.message)
            }
          })
        })
      } else {
        this.$message({
          type: 'error',
          message: '请输入图形验证码！'
        })
        throw new Error("请输入图形验证码")
      }
    },
    //更新验证码
    captcheClick() {
      this.getImageCode()
    },
    // 显示密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    changeUserName() {
      // console.log('cc', this.loginForm.userName );
      if (this.loginForm.username && !isPhone(this.loginForm.username)) {
        this.dis = false
      }
    },
    /* ChangeRember(value){
      this.LoginBtn = !value
    }, */
    // 检查键盘是否打开了大写
    checkCapslock(e) {
      const {key} = e
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z'
    },
    // 点击登录
    async handleLogin() {
      //登录账号和登录密码不为空
      if (this.loginForm.username === "" && this.loginForm.userEname === "") {
        this.$message({
          type: 'error',
          message: '请输入登录账号'
        })
        return
      }
      if (this.loginForm.password === "") {
        this.$message({
          type: 'error',
          message: '请输入登录密码'
        })
        return
      }
      try {
        await this.getCheck()
      } catch (error) {
        // handle error here, for example, show a message to user
        // this.$message({
        //   type: 'error',
        //   message: '验证码错误'
        // })
        return; // if getCheck() failed, stop the execution here
      }
      this.$refs.loginDataForm.validate((valid) => {
        if (valid) {
          debugger
          this.loading = true
          const {username, userEname, password} = this.loginForm
          const obj = {
            account: this.activeName === '1' ? username : userEname,
            userRole: this.activeName === '1' ? 1 : 2,
            password: md5(password),
            captchaToken: this.captchaToken,
            rememberMe: true
            // sign: sign
          }
          console.log(obj)
          // 后面编写提交到接口
          console.log(this.$store)
          this.$store.dispatch('user/login', obj).then((res) => {
            console.log(res)
            this.loading = false
            if (res.code === 0) {
              /*if(this.sourceUrl) { // 来源地址url有值跳回来源页面
                console.log('enenen');
                this.$router.push({
                  name: 'MyCenter'
                })
                /* setTimeout(() =>{
                  location.reload()

                },300) */
              /*}else {*/
              // 判断用户的资料是否填写完成
              if (res.data.uinfoFilled === false) {
                // 个人资料未完善
                this.$message({
                  type: 'warning',
                  message: '请先完善个人资料！'
                })
                this.filledUserInfoDialog = true
                this.getdata();
                // this.$router.push({
                //   // 登录成功跳转至首页
                //   path: '/'
                // })
              } else {
                this.$router.push({
                  // 登录成功跳转至首页
                  path: '/'
                })
                setTimeout(() => {
                  location.reload()
                }, 50)
                /*}*/
              }
            }
          })
          this.loading = false
        }
      })
    },
    getdata() {
      getUserDetail().then((res) => {
        console.log(res)
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.info = res.data.data
          }
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })

    },
    // 找回密码
    findPassword() {
      this.$router.push({
        path: '/password/reset'
      })
    },
    btnSave() {
      let isTag1 = true // 标记
      let isTag2 = true
      if(this.info.email) {
        // console.log(this.ruleForm.email);
        // console.log(validEmail(this.ruleForm.email));
        if(!validEmail(this.info.email)) {
          this.$message({
            type: 'error',
            message: '请输入正确邮箱！'
          })
          isTag1 = false
          // this.handleSubmit()

        }else{
          isTag1 = true
        }
      }
      if(this.info.idCard){
        if(isId15(this.info.idCard) || isId18(this.info.idCard)) {
          this.$message({
            type: 'error',
            message: '请输入正确身份证号码！'
          })
          isTag2 = false
        }else{
          isTag2 = true
        }
      }
      if(isTag1 && isTag2) {
        this.handleSubmit()
      }
    },
    logout() {
      //filledUserInfoDialog为true时，刷新触发退出登录
      if(this.filledUserInfoDialog) {
        this.goOut()
      }
    },
    async goOut() {
      await this.$store.dispatch('user/logout').then(res=>{
        console.log(res);
        if(res.status === 200) {
          if(res.data.code === 0) {
            this.$router.push({
              path: '/'
            })
            setTimeout(() =>{
              location.reload()
            },300)
          }
        }
      })
    },
    handleSubmit() {
      postUserDetail(this.info).then(res=>{
        if(res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '修改成功！',
            onClose: ()=>{
              this.filledUserInfoDialog = false
              this.$router.push({
                // 登录成功跳转至首页
                path: '/'
              })
              setTimeout(() => {
                location.reload()
              }, 50)
            }
          })
        }else{
          this.$message({
            type: 'error',
            message: res.data.message,
            onClose: ()=>{
            }
          })
        }
      })
    },
    // 切换登录方式
    handleClick(tab) {
      console.log(tab.name);
      this.activeName = tab.name
      console.log(typeof (this.activeName));
      this.loginForm.username = ''
      this.loginForm.userEname = ''
      this.loginForm.password = ''
      this.loginForm.captcha = ''
    }
    /* handlegetBtn(){
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.isGrey = true;
        this.show = false;
        this.dis = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) { 
            this.count--;
          } else {
            this.dis = false;
            this.isGrey = false;
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    }, */
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-info-content {
  padding: 20px;
  border-radius: 5px;
}

/* Increasing the spacing between each row */
.user-info-content .el-row {
  margin: 10px 0; /* 10px top and bottom margin, adjust this value as you see fit */
}

.l-title {
  font-weight: bold;
  text-align: right;
  margin-right: 10px;
  line-height: 32px;
}

.el-col {
  display: flex;
  align-items: center; /* Vertically center the content */
}

.r-cont .el-radio-group {
  display: flex;
  align-items: center; /* Ensures radio buttons and their labels are aligned */

}

.r-cont .el-radio {
  margin: 0 10px 0 0; /* Adjust spacing between radio buttons */
  padding: 0;
}

.main {
  background: #f6f6f6;

  .location {
    margin: 20px auto 0;
    // border:1px #f00 solid;
    width: 1200px;
    text-align: left;
  }

  .wrapper {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    // margin-top: 25px;
  }

  .entry-container {
    height: 520px;
    margin-top: 25px;
    border: 1px solid #ededed;
    box-sizing: border-box;
    background: #fff;

    .left-container {
      width: 100%;
      height: 420px;
      padding: 10px;
      margin: 0px auto;
      box-sizing: border-box;
      // float: left;
      // margin-top: 15px;
      .layui-form {
        padding-left: 100px;
      }

      .layui-tab {
        // margin: 10px 0;
        text-align: left !important;
      }

      .layui-tab-title {
        border: 0;
        position: relative;
        left: 0;
        height: 40px;
        white-space: nowrap;
        font-size: 0;
        border-color: #e6e6e6;

        li {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          position: relative;
          line-height: 40px;
          min-width: 65px;
          padding: 0 15px;
          text-align: center;
          cursor: pointer;
          list-style: none;

          a {
            display: block;
            border-bottom: 2px solid #0065b3;
            cursor: pointer;
            text-decoration: none;
            outline: none;
          }
        }

        .layui-this {
          color: #0065b3;
        }
      }
    }

    .right-container {
      height: 420px;
      padding: 10px;
      margin: 50px 0 0 0;
      box-sizing: border-box;
      float: right;
      border-left: 1px solid #dedede;
      text-align: center;

      .qr-item {
        margin-bottom: 20px;
      }

      .qr {
        img {
          width: 100%;
          height: 100%;
          margin: 0 59px;
        }
      }
    }
  }

  input {
    margin-top: 20px;
  }

  .mb20 {
    margin-bottom: 20px !important;
  }

  .shadow {
    box-shadow: 0 0 1px 0px #f8f8f8;
  }

  form {
    display: block;
  }

  .captcha {
    float: left;
    width: 140px;
  }

  .form_width {
    width: 600px;
    margin: 80px auto;

    .username,
    .password {
      float: left;
      width: 240px;
      /* .show-pwd {
          position: absolute;
          right: 10px;
          top: 7px;
          font-size: 16px;
          color: $dark_gray;
          cursor: pointer;
          user-select: none;
        } */
    }

    .btn-wrap {
      text-align: center;
      float: left;
      margin: 0 0 0 180px;

      .submit-btn {
        width: 240px;
        border: none;
      }
    }

    .sendCode {
      margin-right: 150px;
      width: 95px;
      background: #e4393c;
      border: none;
    }

    .el-button--primary {
      /* background-color: #e4393c; */
    }

    .el-button--primary[disabled] {
      color: #fff !important;
      opacity: 0.5;
    }

    .captchaImage {
      float: left;
      width: 88px;
      height: 30px;
      margin-left: 10px;
      border: 1px #eee solid;
    }

    .find-password {
      margin: 70px 0 0 180px;
      width: 100%;
      font-size: 14px;
      text-align: left;
    }

    .find-password:hover {
      color: #0065b3;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>